import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../model/apiresponse.model';
import { environment } from 'src/environments/environment';
import { Organization } from '../../model/organization.model';
import { ApiPaginatedResponse } from '../../model/api-paginated-response.model';
import { User } from '../../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient) { }

  get(id: string): Observable<ApiResponse<Organization | undefined>> {
    return this.http.get<any>(
      `${environment.apiUrl}/organization/${id}`,
      {
        params: {
          'with[]': ['cluster']
        }
      }
    );
  }

  getLogo(id: string): Observable<Blob> {
    return this.http.get(
      `${environment.apiUrl}/organization/${id}/logo`,
      {
        headers: new HttpHeaders({'Accept': 'image/png,image/jpg,image/jpeg'}),
        responseType: 'blob'
      }
    )
  }

  list(): Observable<ApiResponse<Organization[] | undefined>> {
    return this.http.get<ApiResponse<Organization[] | undefined>>(
      `${environment.apiUrl}/organization`
    );
  }

  filteredList(filter: string, filterValue: string): Observable<ApiResponse<Organization[] | undefined>> {
    let params: { [Key: string]: any } = {
    };

    if (filterValue != null && filterValue !== '') {
      params = {
        filterBy: filter,
        filter: filterValue,
        orderBy: filter,
        asc: 1
      };
    }
    return this.http.get<any>(
      `${environment.apiUrl}/organization`,
      {
        params: params
      }
    );
  }

  paginatedList(page: number, pageSize: number): Observable<ApiPaginatedResponse<Organization[] | undefined>> {
    return this.http.get<ApiPaginatedResponse<Organization[] | undefined>>(
      `${environment.apiUrl}/organization`,
      {
        params: {
          page: page,
          limit: pageSize,
          orderBy: 'name',
          asc: 1,
          'with[]': ['cluster'],
          'withCount[]': ['members']
        }
      }
    );
  }

  filteredPaginatedList(page: number, pageSize: number, filter: string, filterValue: string): Observable<ApiPaginatedResponse<Organization[] | undefined>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'name',
      asc: 1,
      'with[]': ['cluster'],
      'withCount[]': ['members']
    };

    if (filterValue != null && filterValue !== '') {
      params['orderBy'] = filter;
      params['filterBy'] = filter;
      params['filter'] = filterValue;
    }

    return this.http.get<ApiPaginatedResponse<Organization[] | undefined>>(
      `${environment.apiUrl}/organization`,
      {
        params: params
      }
    );
  }

  create(
    clusterId: string,
    organization: Organization,
    logo?: Blob
  ): Observable<ApiResponse<Organization | undefined>> {

    if (logo != null) {
      const formData = new FormData();
      formData.append('logo', logo);
      Object.entries(organization).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((el, index) => {
            formData.append(`${key}[${index}]`, el);
          });
        } else {
          formData.append(key, value);
        }
      });
      return this.http.post<any>(
        `${environment.apiUrl}/cluster/${clusterId}/organization`,
        formData
      );
    } else {
      return this.http.post<any>(
        `${environment.apiUrl}/cluster/${clusterId}/organization`,
        organization
      );
    }
  }

  delete(organizationId: string): Observable<ApiResponse<Organization | undefined>> {
    return this.http.delete<any>(
      `${environment.apiUrl}/organization/${organizationId}`
    );
  }

  disable(organizationId: string, disabled: boolean): Observable<ApiResponse<Organization | undefined>> {
    return this.http.put<any>(
      `${environment.apiUrl}/organization/${organizationId}`,
      {
        disabled: disabled
      }
    );
  }

  edit(
    organization: Organization,
    logo?: Blob
  ) {
    
    if (logo != null) {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('logo', logo);
      Object.entries(organization).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((el, index) => {
            formData.append(`${key}[${index}]`, el);
          });
        }
      });
      return this.http.post<any>(
        `${environment.apiUrl}/organization/${organization.id}`,
        formData
      );
    } else {
      return this.http.put<any>(
        `${environment.apiUrl}/organization/${organization.id}`,
        organization
      );
    }
  }

  getUsers(organizationId: string): Observable<ApiResponse<User[] | undefined>> {
    return this.http.get<ApiResponse<User[] | undefined>>(
      `${environment.apiUrl}/organization/${organizationId}/user`,
      {
        params: {
          orderBy: 'created_at'
        }
      }
    );
  }

  getPaginatedUsers(organizationId: string, page: number, pageSize: number): Observable<ApiPaginatedResponse<User[] | undefined>> {
    
    return this.http.get<ApiPaginatedResponse<User[] | undefined>>(
      `${environment.apiUrl}/organization/${organizationId}/user`,
      {
        params: {
          page: page,
          limit: pageSize,
          orderBy: 'created_at'
        }
      }
    );
  }

  getFilteredPaginatedUsers(organizationId: string, page: number, pageSize: number, filter: string, filterValue: string): Observable<ApiPaginatedResponse<User[] | undefined>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'created_at',
      asc: 1,
      'with[]': ['cluster']
    };

    if (filterValue != null && filterValue !== '') {
      params['orderBy'] = filter;
      params['filterBy'] = filter;
      params['filter'] = filterValue;
    }

    return this.http.get<ApiPaginatedResponse<User[] | undefined>>(
      `${environment.apiUrl}/organization/${organizationId}/user`,
      {
        params: params
      }
    );
  }

  getUser(organizationId: string, userId: string): Observable<ApiResponse<User | undefined>> {
    return this.http.get<ApiResponse<User | undefined>>(
      `${environment.apiUrl}/organization/${organizationId}/user/${userId}`,
      {
        params: {
          
        }
      }
    );
  }


  createUser(id: string, email: string, name: string, last_name: string): Observable<ApiResponse<User | undefined>> {
    return this.http.post<ApiResponse<User | undefined>>(
      `${environment.apiUrl}/organization/${id}/user`,
      {
        email: email,
        name: name,
        last_name: last_name
      }
    );
  }

  updateUser(id: string, userId: string, name: string, lastName: string): Observable<ApiResponse<User | undefined>> {
    return this.http.put<ApiResponse<User | undefined>>(
      `${environment.apiUrl}/organization/${id}/user/${userId}`,
      {
        name: name,
        last_name: lastName
      }
    );
  }

  disableUser(organizationId: string, userId: string, disabled: boolean): Observable<ApiResponse<User | undefined>> {
    return this.http.put<ApiResponse<User | undefined>>(
      `${environment.apiUrl}/organization/${organizationId}/user/${userId}/disable`,
      {
        disabled: disabled
      }
    );
  }

  deleteUser(organizationId: string, userId: string):Observable<ApiResponse<User | undefined>> {
    return this.http.delete<ApiResponse<User | undefined>>(
      `${environment.apiUrl}/organization/${organizationId}/user/${userId}`
    );
  }

  getAdmins(organizationId: string): Observable<ApiResponse<User[] | null>> {
    return this.http.get<ApiResponse<User[] | null>>(
      `${environment.apiUrl}/organization/${organizationId}/user`,
      {
        params: {
          orderBy: 'created_at',
          role: 'organization-admin'
        }
      }
    );
  }

  createAdmin(id: string, email: string, name: string, last_name: string): Observable<ApiResponse<User | undefined>> {
    return this.http.post<ApiResponse<User | undefined>>(
      `${environment.apiUrl}/organization/${id}/admin`,
      {
        email: email,
        name: name,
        last_name: last_name
      }
    );
  }

  deleteAdmin(organizationId: string, userId: string): Observable<ApiResponse<User | null>> {
    return this.http.delete<any>(
      `${environment.apiUrl}/organization/${organizationId}/user/${userId}`,
    );
  }

  addExternalUser(id: string, email: string, name: string, last_name: string) {
    return this.http.post<ApiResponse<User | null>>(
      `${environment.apiUrl}/organization/${id}/add-external-user`,
      {
        email: email,
        name: name,
        last_name: last_name
      }
      );
  }

  transferOrganizationToAnotherCluster(organizationId: string, clusterId: string) {
    return this.http.put<any>(
      `${environment.apiUrl}/organization/${organizationId}/transfer`,
      {
        cluster_id: clusterId
      }
    )
  }
}

